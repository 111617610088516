import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="What To Expect" />

    <div>
      <h1>
        What to <span className="text-blue-600">expect?</span>
      </h1>

      <p>
        Many people turn to friends and family in times of difficulty, although
        they tend to hold back, not wanting to ‘burden’ their friends and
        family. Whether the issue is depression, bereavement, anxiety, abuse,
        disability or anything else, sometimes people find that family and
        friends can be too close to the situation to talk to. Often well-meaning
        friends and family want to fix things and make things better and
        therefore can find it difficult just to listen, leaving the person with
        a sense of not being heard.
      </p>
      <p>
        As a counsellor I am trained to listen, I will not judge you or try to
        fix things. Instead I aim to offer you the space to untangle your
        thoughts and feelings in order for you to see your own way forward more
        clearly.
      </p>
      <p>
        I came across this page by Megan Hale on the internet and she writes
        beautifully about what to expect from therapy.
      </p>
      <p>
        Being a therapist can be an amazing profession full of challenges,
        heartaches, and celebration. We see you at your worst and see you at
        your best, but there is no better reward to see you succeed. Here are 10
        aspects of the therapeutic relationship that are either unknowns or
        common misconceptions. I hope this clarifies what you can expect from
        working with a therapist.
      </p>
      <h2>1. I don’t think you’re crazy.</h2>
      <p>
        I think you are amazingly unique trying to find your way in the world.
        None of us is perfect and I surely don’t expect you to be anywhere close
        to mastery when you’re learning new skills to change your life.
        Effective change usually requires trial and lots of errors. It means
        you’re trying! Plus, if I think you’re being irrational, I’ll tell you.
      </p>
      <h2>2. Trust is everything.</h2>
      <p>
        Your ability to connect with me will be the number one factor
        determining how well we work together. If you don’t feel like you click
        with me after a few sessions, it’s OK to let me know and seek out a
        different therapist. We all need different things and my main priority
        is for you to achieve your goals.
      </p>
      <h2>3. My job is not to psychoanalyze you.</h2>
      <p>
        My job is to be curious and to help you gain more understanding. A good
        therapist doesn’t claim to have all the answers for why you are the way
        you are although we may have some ideas that we will willingly share
        with you. When it comes to getting answers and more understanding, we
        will form hypotheses together and you will come to your own conclusions.
        A therapist facilitates that process. They don’t tell you how to
        think/believe/act.
      </p>
      <h2>4. I’m not here to give you advice.</h2>
      <p>
        I’m here to share my knowledge with you and help you make your own
        decisions that are balanced, rational, and well-explored. Strengthening
        your own reasoning and decision-making skills will increase your
        independence and self-esteem. Win-win!
      </p>
      <h2>
        5. Work through your emotions with me instead of quitting, anger
        included.
      </h2>
      <p>
        Therapy is the perfect place to learn how to express your feelings.
        That’s what I’m here for, to give you a space to try out new ways of
        being, thinking, and feeling. Take advantage of this. When we learn how
        to work through our negative emotions with others, it increases our
        relationship skills and makes us more comfortable with voicing our
        hurts. This is a necessary component to maintaining relationships and
        managing your emotions in a healthy way.
      </p>
      <h2>
        6. I expect you to slide backward to old behavior patterns and I’m not
        here to judge you.
      </h2>
      <p>
        Most people judge themselves enough for at least two people. I encourage
        my clients to come clean. It’s only through acknowledging our steps
        backward that we can figure out what’s standing in the way so that you
        can catapult forward. Relapse is VERY common and it’s nothing to be
        ashamed of.
      </p>
      <h2>7. You deserve to be happy.</h2>
      <p>
        Happiness is not reserved for special people. Everyone has regrets,
        things we wish we never would have done, people we’ve hurt along the
        way, people who have hurt us either intentionally or unintentionally.
        I’m a firm believer that we can heal our wounds and step into happiness.
        You deserve it just as much as the next person.
      </p>
      <h2>8. I can’t “fix” your life or your problems. Only you can.</h2>
      <p>
        I can help you gain more clarity, more understanding, and form a plan of
        action, but therapy is not a magic pill that erases all issues. It takes
        work, but if you’re up for the challenge, I’ll be there every step of
        the way!
      </p>
      <h2>9. The quickest way from point A to point B is action.</h2>
      <p>
        If you continue to come to therapy without putting any new behaviors or
        thoughts into action, progress will be a slow process for you. The path
        to action is different for everyone, but if you never do anything
        different, you’ll never get a different result. You’re the only one who
        can decide to take action. You hold all the power.
      </p>
      <h2>10. I want you to have the life you want.</h2>
      <p>
        I know your struggles, your dreams, your insecurities. There is nothing
        I want more for you than for you to bring your dreams into reality, push
        through your fears, and have the life you want. Your success is the
        ultimate gift to a therapist!
      </p>
      <p>
        People come to therapy for all kinds of reasons. Usually people are
        experiencing a moderate level of discomfort in their lives and have
        noticed a toll on their work/school performance and in their
        relationships. Beginning therapy can be scary for some as they are
        showing a willingness to face tough topics, but for others, it’s a huge
        relief to finally be taking action to move in a different direction.
      </p>
      <p>
        Therapy isn’t always easy, but I think it’s the most worthwhile gift you
        can give yourself. Find someone you trust and who puts you at ease. The
        relationship you build with your therapist is the most important aspect
        of all.
      </p>
      <p>
        To read this page go to…
        http://www.mindbodygreen.com/0-12657/10-things-i-wish-everyone-knew-about-therapy.html…
        thanks Megan
      </p>
    </div>
  </Layout>
)

export default IndexPage
